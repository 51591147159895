import React from "react";
// import FooterLogo from "../assets/footer-logo.svg";
import FooterLogo from "../assets/james-logo.png";
import { MdOutlineArrowUpward } from "react-icons/md";
import resume from "../assets/JamesHowieCV.pdf";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <a href="#" className="footer__logo">
        <img src={FooterLogo} alt="" className="me__logo" />
        <span className="footer__scroll-top">
          Top <MdOutlineArrowUpward />
        </span>
      </a>
      <div className="footer__link--wrapper">
        <div>
          <a target="_blank" href="https://github.com/Jameshw19">
            Github
          </a>
        </div>
        <div>
          <a target="_blank" href="https://www.linkedin.com/in/thejameshowie/">
            LinkedIn
          </a>
        </div>
        <div>
          <a target="_blank" href="">
            Email
          </a>
        </div>
        <div>
          <a target="_blank" href={resume}>
            Resume
          </a>
        </div>
      </div>
      <div>Copyright &copy; 2023 James Howie</div>
    </div>
  );
};

export default Footer;
