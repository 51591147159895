export const technicalSkillsConfig = [
  "HTML5",
  "CSS3 (+ SCSS, BEM)",
  "JavaScript ES6+",
  "React (+ hooks)",
  "NextJS",
  "Firebase v9",
  "Redux",
  "TypeScript",
];
