export const transferrableSkillsConfig = [
  "Confident leadership skills",
  "Exceptional communication skills",
  "Outstanding work ethic",
  "Effective teaching skills",
  "Professional client relations",
  "Quick learner",
  "Creative problem solver",
  "Extraordinary time management skills",
];
