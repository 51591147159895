import NftCoverImage from "../assets/project-images/NFT/nft-cover.png";
import NftFrontImage from "../assets/project-images/NFT/nft-front.png";
import NftFirstImage from "../assets/project-images/NFT/nft-desktop.png";
import NftSecondImage from "../assets/project-images/NFT/nft-phones-1.jpg";
import NftThirdImage from "../assets/project-images/NFT/nft-phones-2.jpg";

import SummaristCover from "../assets/project-images/ecommerce/Summarist-Home.png";
import SumaristTop from "../assets/project-images/ecommerce/Sumarist-3-devices.png";
import SummaristFirstImage from "../assets/project-images/ecommerce/Summarist-bookMain.png";
import Sumarist2Phones from "../assets/project-images/ecommerce/sumarist-2phones.png";
import SumaristLastImage from "../assets/project-images/ecommerce/sumarist-2phones-last.png";

import LmsCover from "../assets/project-images/lms/lms-cover.png";
import LmsFrontImage from "../assets/project-images/lms/lms-front.png";
import LmsFirstImage from "../assets/project-images/lms/lms-1.png";
import Lms2Phones from "../assets/project-images/lms/lms-2.png";
import LmsThirdImage from "../assets/project-images/lms/lms-3.png";

import NotesCover from "../assets/project-images/notes/notes-cover.png";
import NotesFrontImage from "../assets/project-images/notes/notes-front.png";
import NotesFirstImage from "../assets/project-images/notes/notes-1.png";
import Notes2Phones from "../assets/project-images/notes/notes-phones.png";
// import NotesThirdImage from "../assets/project-images/Notes/Notes-3.png";

import FinanceCover from "../assets/project-images/finance/finance-cover.png";
import FinanceFrontImage from "../assets/project-images/finance/finance-top.png";

const projectsConfig = [
  {
    title: "Finance App",
    type: "Web App",
    description:
      "Developed a finance app using Next.js, React, Hono.js, Drizzle ORM, and Neon DB, allowing Clerk sign-in, CSV uploads, and viewing transactions by various criteria. Users can analyze income, expenses, and balances over 30 days with interactive graphs and charts.",
    liveLink: "https://finance-app-eta-five.vercel.app",
    githubLink: "https://github.com/Jameshw19/finance-app",
    coverImage: FinanceCover,
    topImage: FinanceFrontImage,
    // firstImage: NotesFirstImage,
    // secondImage: Notes2Phones,
    // thirdImage: NotesThirdImage,
  },
  {
    title: "NFT Marketplace",
    type: "Web App",
    description:
      "NFT web3 app that empowers users to generate, trade, and vend distinctive digital assets, revolutionizing the concept of ownership and transactions in the digital sphere.",
    liveLink: "https://james-internship.vercel.app/",
    githubLink: "https://github.com/Jameshw19/james-internship",
    coverImage: NftCoverImage,
    topImage: NftFrontImage,
    firstImage: NftFirstImage,
    secondImage: NftSecondImage,
    thirdImage: NftThirdImage,
  },
  {
    title: "Summarist",
    type: "Web App",
    description:
      "an innovative book app that grants users the flexibility to either engage with audio books through narration or enjoy traditional reading, showcasing adaptability in delivering literary content.",
    liveLink: "https://summarist.vercel.app/",
    githubLink: "https://github.com/Jameshw19/adv-intern-fix",
    coverImage: SummaristCover,
    topImage: SumaristTop,
    firstImage: SummaristFirstImage,
    secondImage: Sumarist2Phones,
    thirdImage: SumaristLastImage,
  },
  {
    title: "Notes App",
    type: "Web App",
    description:
      "Developed a cutting-edge notes app using Next.js and TypeScript, powered by Shadcn UI, Convex, and React technologies, while leveraging the flexibility of Tailwind CSS for a seamless and intuitive note-taking experience, setting new standards for productivity and organization. ",
    liveLink: "https://notes-app-three-drab.vercel.app",
    githubLink: "https://github.com/Jameshw19/notes-app",
    coverImage: NotesCover,
    topImage: NotesFrontImage,
    firstImage: NotesFirstImage,
    secondImage: Notes2Phones,
  },

  {
    title: "LMS Platform",
    type: "Web App",
    description:
      "Designed a modern LMS platform that enhances the learning experience, simplifies course management, and facilitates interactive assessments, opening new doors to efficient education and training. ",
    liveLink: "https://lms-platform-ecru.vercel.app",
    githubLink: "https://github.com/Jameshw19/lms-platform",
    coverImage: LmsCover,
    topImage: LmsFrontImage,
    firstImage: LmsFirstImage,
    secondImage: Lms2Phones,
    thirdImage: LmsThirdImage,
  },
];

export default projectsConfig;
