export const experienceConfig = [
  {
    name: "Frontend Software Engineer ",
    title: "We-Mu Studio",
    description: [
      "Led a team to successfully translate project requirements into actionable technical specifications, resulting in the delivery of software applications that reduced customer support issues by 40% and increased team efficiency by 30%",
      "Developed and applied innovative software solutions for 10+ diverse client projects, resulting in a 50% reduction in customer support issues and achieving a client satisfaction rating of 95%",
      "Optimized server-side functionalities and enhanced user experiences by implementing responsive design techniques, leading to a 15% increase in mobile user engagement and a 10% boost in mobile conversion rates.",
    ],
    date: "Jan 2022 - Jan 2024",
  },
  {
    name: "Frontend Developer Internship",
    title: "Frontend Simplified",
    description: [
      "Transformed static HTML, CSS, JavaScript, and React single page application into a highly engaging user interface using animations, seamless transitions, and captivating carousels; increased user engagement by 50% and reduced bounce rate by 25%",
      "Enhanced user experience by implementing Axios for API requests and utilizing skeleton loading, pagination, and dynamic routing. Achieved a significant 50% decrease in loading times, resulting in improved user satisfaction and engagement.",
      "Implemented Git version control and the GitHub interface to work and thrive in a virtual and collaborative team environment.",
    ],
    date: "Oct 2021 - Jan 2022",
  },
  {
    name: "Frontend Developer Support",
    title: "Frontend Simplified",
    description: [
      "Guided 1,000+ students using HTML, CSS, Flexbox, JavaScript and React best practices",
      "Conceptualized debugging best practices using Google Chrome inspect element tooling.",
      "Simplified difficult programming concepts including responsive website development, CSS layout, Promises and API requests.",
    ],
    date: "Jul 2020 - Oct 2021",
  },
];
